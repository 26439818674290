<template>

  <div class="service_form_details">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="6"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              {{ serviceForm.stringID }} - {{ serviceForm.customerName }}
            </h1>
            <p class="log_info">
              Created by {{ serviceForm.createdBy ? serviceForm.createdBy.name : '' }} on {{ dateFormatWithTime(serviceForm.createdAt) }}<br>Last updated on {{ dateFormatWithTime(serviceForm.updatedAt) }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block d-none mb-1"
        md="6"
        cols="12"
      >
        <div class="user_block ac_type">
          <div class="user_avatar" />
          <div class="user_info">
            <h6>Status</h6>
            <h5 class="text-capitalize">
              {{ serviceForm.status ? serviceForm.status.replace("-", " ") : '' }}
            </h5>
          </div>
          <div class="action_button">
            <b-button
              v-if="canViewThisAction('update', 'ServiceForm') || canViewThisAction('update-status', 'ServiceForm')"
              variant="flat-primary"
              @click="showModalOrWarning"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
            </b-button>
            <b-button
              v-else
              v-b-tooltip.hover.bottom="'You don’t have permission to edit this information.'"
              variant="flat-primary"
              style="opacity: 0.65;"
              aria-readonly=""
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
            </b-button>
          </div>
        </div>
        <div class="user_block">
          <div class="user_avatar">
            <img :src="serviceForm.dutyOfficer ? (serviceForm.dutyOfficer.image ? serviceForm.dutyOfficer.image : defaultImage) : defaultImage">
          </div>
          <div class="user_info">
            <h6>Duty Officer</h6>
            <h5>{{ serviceForm.dutyOfficer ? serviceForm.dutyOfficer.name : '' }}</h5>
          </div>
          <div class="action_button">
            <b-button
              v-if="canViewThisAction('update', 'ServiceForm') || canViewThisAction('update-duty-officer', 'ServiceForm')"
              v-b-modal.modal-update-duty-officer
              variant="flat-primary"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
            </b-button>
            <b-button
              v-else
              v-b-tooltip.hover.bottom="'You don’t have permission to edit this information.'"
              variant="flat-primary"
              style="opacity: 0.65;"
              aria-readonly=""
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      class="mb-3 p-2 calllog-details serviceForm_details"
    >
      <b-row
        class="content-header"
        style="flex-wrap: nowrap;"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          md="9"
        >
          <h3>Applicant Details</h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right mb-1"
          md="3"
        >
          <b-button
            v-if="canViewThisAction('update', 'ServiceForm') || canViewThisAction('update-applicant', 'ServiceForm')"
            variant="flat-primary"
            :to="{ name: 'operation-service-forms-edit', params: { id: $route.params.id }, query: { section: 'applicant_detail' } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
          <b-button
            v-else
            v-b-tooltip.hover.bottom="'You don’t have permission to edit this information.'"
            variant="flat-primary"
            style="opacity: 0.65;"
            aria-readonly=""
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Customer Name
            </b-th>
            <b-td>{{ serviceForm.customerName }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Mobile Number
            </b-th>
            <b-td>{{ serviceForm.customerContact ? serviceForm.customerContact : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Email
            </b-th>
            <b-td>{{ serviceForm.customerEmail ? serviceForm.customerEmail : '-' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR">
      <b-row
        class="content-header"
        style="flex-wrap: nowrap;"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          md="11"
        >
          <h3>Particulars of Deceased</h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right mb-1"
          md="1"
        >
          <b-button
            v-if="canViewThisAction('update', 'ServiceForm') || canViewThisAction('update-deceased', 'ServiceForm')"
            variant="flat-primary"
            :to="{ name: 'operation-service-forms-edit', params: { id: $route.params.id }, query: { section: 'deceased_detail' } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
          <b-button
            v-else
            v-b-tooltip.hover.bottom="'You don’t have permission to edit this information.'"
            variant="flat-primary"
            style="opacity: 0.65;"
            aria-readonly=""
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
        class="particulars_of_deceased d-none d-md-block"
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Niche No.
            </b-th>
            <b-td>
              <b-button
                v-if="serviceForm.deceasedNiche"
                variant="link"
                style="font-size: 14px; color: #104D9D; padding: 0; display: flex; line-height: 1.358rem;"
                :to="{ name: 'operation-service-requests-index', params: { type: 'all-service-requests' }, query: { search: serviceForm.deceasedNiche, searchType: 'deceasedNiche' } }"
              >
                {{ serviceForm.deceasedNiche }}
              </b-button>
              <span v-else>-</span>
            </b-td>
            <b-th width="230px">
              Name of Deceased
            </b-th>
            <b-td>{{ serviceForm.deceasedName ? serviceForm.deceasedName : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Pedestal No.
            </b-th>
            <b-td>
              <b-button
                v-if="serviceForm.deceasedPedestal"
                variant="link"
                style="font-size: 14px; color: #104D9D; padding: 0; display: flex; line-height: 1.358rem;"
                :to="{ name: 'operation-service-requests-index', params: { type: 'all-service-requests' }, query: { search: serviceForm.deceasedPedestal, searchType: 'deceasedPedestal' } }"
              >
                {{ serviceForm.deceasedPedestal }}
              </b-button>
              <span v-else>-</span>
            </b-td>
            <b-th width="230px">
              Departed Date
            </b-th>
            <b-td>{{ serviceForm.departedDate ? dateFormat(serviceForm.departedDate) : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Anling Lot No.
            </b-th>
            <b-td>
              <b-button
                v-if="serviceForm.deceasedAngling"
                variant="link"
                style="font-size: 14px; color: #104D9D; padding: 0; display: flex; line-height: 1.358rem;"
                :to="{ name: 'operation-service-requests-index', params: { type: 'all-service-requests' }, query: { search: serviceForm.deceasedAngling, searchType: 'deceasedAngling' } }"
              >
                {{ serviceForm.deceasedAngling }}
              </b-button>
              <span v-else>-</span>
            </b-td>
            <b-th width="230px">
              Age and Gender
            </b-th>
            <b-td>{{ serviceForm.deceasedAge ? serviceForm.deceasedAge : '-' }}, <span class="text-capitalize">{{ serviceForm.deceasedGender ? serviceForm.deceasedGender : '-' }}</span></b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <b-table-simple
        borderless
        responsive
        class="particulars_of_deceased d-block d-md-none"
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Name of Deceased
            </b-th>
            <b-td>{{ serviceForm.deceasedName ? serviceForm.deceasedName : '-' }}</b-td>
            <b-th width="230px">
              Niche No.
            </b-th>
            <b-td>
              <b-button
                v-if="serviceForm.deceasedNiche"
                variant="link"
                style="font-size: 14px; color: #104D9D; padding: 0; display: flex; line-height: 1.358rem;"
                :to="{ name: 'operation-service-requests-index', params: { type: 'all-service-requests' }, query: { search: serviceForm.deceasedNiche, searchType: 'deceasedNiche' } }"
              >
                {{ serviceForm.deceasedNiche }}
              </b-button>
              <span v-else>-</span>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Pedestal No.
            </b-th>
            <b-td>
              <b-button
                v-if="serviceForm.deceasedPedestal"
                variant="link"
                style="font-size: 14px; color: #104D9D; padding: 0; display: flex; line-height: 1.358rem;"
                :to="{ name: 'operation-service-requests-index', params: { type: 'all-service-requests' }, query: { search: serviceForm.deceasedPedestal, searchType: 'deceasedPedestal' } }"
              >
                {{ serviceForm.deceasedPedestal }}
              </b-button>
              <span v-else>-</span>
            </b-td>
            <b-th width="230px">
              Anling Lot No.
            </b-th>
            <b-td>
              <b-button
                v-if="serviceForm.deceasedAngling"
                variant="link"
                style="font-size: 14px; color: #104D9D; padding: 0; display: flex; line-height: 1.358rem;"
                :to="{ name: 'operation-service-requests-index', params: { type: 'all-service-requests' }, query: { search: serviceForm.deceasedAngling, searchType: 'deceasedAngling' } }"
              >
                {{ serviceForm.deceasedAngling }}
              </b-button>
              <span v-else>-</span>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Departed Date
            </b-th>
            <b-td>{{ serviceForm.departedDate ? dateFormat(serviceForm.departedDate) : '-' }}</b-td>
            <b-th width="230px">
              Age and Gender
            </b-th>
            <b-td>{{ serviceForm.deceasedAge ? serviceForm.deceasedAge : '-' }}, <span class="text-capitalize">{{ serviceForm.deceasedGender ? serviceForm.deceasedGender : '-' }}</span></b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR">

      <b-row
        class="content-header"
        style="flex-wrap: nowrap;"
      >
        <!-- Content Left -->
        <b-col
          class="content-header-left"
          md="9"
        >
          <h3>
            <span class="align-middle">Service Requests</span>
          </h3>
        </b-col>
        <b-col
          class="content-header-right text-md-right"
          md="3"
        >
          <b-button
            v-if="canViewThisAction('create', 'ServiceRequest')"
            variant="flat-primary"
            :to="{ name: 'operation-service-requests-create', query: { form: $route.params.id } }"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Create new Service Request</span>
          </b-button>
        </b-col>
      </b-row>
      <all-service-request
        :request-type-options="requestTypeOptions"
        :assignee-options="assigneeOptions"
        @emitFetchServiceForm="fetchServiceForm"
      />

      <hr class="dividerHR">

      <!-- <b-row
        class="content-header"
        style="flex-wrap: nowrap;"
      >

        <b-col
          class="content-header-left mb-2"
          md="9"
        >
          <h3>
            Service Description
          </h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right mb-1"
          md="3"
        >
          <b-button
            v-if="canViewThisAction('update', 'ServiceForm') || canViewThisAction('update-service-description', 'ServiceForm')"
            variant="flat-primary"
            :to="{ name: 'operation-service-forms-edit', params: { id: $route.params.id }, query: { section: 'service_description' } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
          <b-button
            v-else
            v-b-tooltip.hover.bottom="'You don’t have permission to edit this information.'"
            variant="flat-primary"
            aria-readonly=""
            style="opacity: 0.65;"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>

      <b-table-simple
        borderless
        responsive
        class="service__description"
      >
        <b-tbody>
          <b-tr
            v-for="(opt, key) in serviceForm.serviceDescription"
            :key="key"
          >
            <b-th
              v-if="opt.key"
              width="35%"
            >
              {{ opt.key }}
              <div v-if="opt.text">
                <span
                  style="font-style: normal; font-weight: 400; font-size: 14px;"
                >
                  {{ opt.text }}
                </span>
              </div>
            </b-th>
            <b-td
              v-if="opt.key"
            >
              <span
                v-if="Array.isArray(opt.value)"
              >
                <div v-if="opt.value.length">
                  <span
                    v-for="(option, index) in opt.value"
                    :key="index"
                  >
                    {{ option }} <span v-if="index + 1 != opt.value.length">, </span>
                  </span>
                </div>
                <div v-else>
                  <span>-</span>
                </div>
              </span>
              <span v-else>{{ opt.value ? opt.value : (opt.text ? '' : '-') }}</span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR"> -->

      <b-row
        class="content-header"
        style="flex-wrap: nowrap;"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          md="9"
        >
          <h3>Payment Details (Service Form)</h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right mb-1"
          md="3"
        >
          <b-button
            v-if="!canViewThisAction('update-payment', 'ServiceForm') && canViewThisAction('update', 'ServiceForm')"
            variant="flat-primary"
            :to="{ name: 'operation-service-forms-edit', params: { id: $route.params.id }, query: { section: 'payment_details' } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
          <b-button
            v-else-if="canViewThisAction('update-payment', 'ServiceForm') && !canViewThisAction('update', 'ServiceForm')"
            variant="flat-primary"
            :to="{ name: 'operation-service-forms-payments-edit', params: { id: $route.params.id } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
          <b-button
            v-else-if="canViewThisAction('update-payment', 'ServiceForm') && canViewThisAction('update', 'ServiceForm')"
            variant="flat-primary"
            :to="{ name: 'operation-service-forms-edit', params: { id: $route.params.id }, query: { section: 'payment_details' } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
          <b-button
            v-else
            v-b-tooltip.hover.bottom="'You don’t have permission to edit this information.'"
            variant="flat-primary"
            aria-readonly=""
            style="opacity: 0.65;"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
        class="payment_table"
      >
        <b-tbody>
          <b-tr>
            <b-th>
              Payment Status
            </b-th>
            <b-td>{{ serviceForm.paymentStatus ? serviceForm.paymentStatus : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Payment Remarks
            </b-th>
            <b-td>{{ serviceForm.paymentRemarks ? serviceForm.paymentRemarks : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="35%">
              Send SMS Reminder to Agent
            </b-th>
            <b-td>{{ serviceForm.smsDate ? dateFormatWithTime(serviceForm.smsDate) : 'No sms sent yet' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="35%">
              Email Finance, Ads and Ops Team
            </b-th>
            <b-td>{{ serviceForm.emailDate ? dateFormatWithTime(serviceForm.emailDate) : 'No email sent yet' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div class="button__actions">
        <b-button
          v-b-modal.modal-send-sms-agent
          variant="primary"
          :class="{'mt-50': isMobile()}"
          class="mr-1"
        >
          Remind Agent
        </b-button>
        <b-button
          variant="primary"
          @click="shallShowEmailComposeModal = true"
        >
          Write Email to Finance, Ads and Ops
        </b-button>
      </div>

      <hr class="dividerHR">

      <b-row
        class="content-header"
        style="flex-wrap: nowrap;"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          md="9"
        >
          <h3>Agent Details</h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right mb-1"
          md="3"
        >
          <b-button
            v-if="canViewThisAction('update', 'ServiceForm') || canViewThisAction('update-agent', 'ServiceForm')"
            variant="flat-primary"
            :to="{ name: 'operation-service-forms-edit', params: { id: $route.params.id }, query: { section: 'agent_detail' } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
          <b-button
            v-else
            v-b-tooltip.hover.bottom="'You don’t have permission to edit this information.'"
            variant="flat-primary"
            style="opacity: 0.65;"
            aria-readonly=""
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Agent Name
            </b-th>
            <b-td>{{ serviceForm.agentName ? serviceForm.agentName : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Agent Code
            </b-th>
            <b-td>{{ serviceForm.agentCode ? serviceForm.agentCode : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Agent Mobile Number
            </b-th>
            <b-td>{{ serviceForm.agentContact ? ('(65) ' + serviceForm.agentContact) : '-' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR">

      <b-row
        class="content-header"
        style="flex-wrap: nowrap;"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          md="9"
        >
          <h3>Attachments</h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right mb-1"
          md="3"
        >
          <b-button
            v-if="canViewThisAction('update', 'ServiceForm') || canViewThisAction('update-attachment', 'ServiceForm')"
            variant="flat-primary"
            :to="{ name: 'operation-service-forms-edit', params: { id: $route.params.id }, query: { section: 'attachment' } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
          <b-button
            v-else
            v-b-tooltip.hover.bottom="'You don’t have permission to edit this information.'"
            variant="flat-primary"
            style="opacity: 0.65;"
            aria-readonly=""
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="serviceForm.attachments && serviceForm.attachments.length">
        <!-- Content Left -->
        <b-col
          v-for="(file, key) in serviceForm.attachments"
          :key="key"
          class="content-header-left mb-2"
          cols="12"
          md="4"
        >
          <div
            class="attachment-item"
          >
            <b-img
              v-if="file.type.includes('image')"
              left
              height="40"
              :src="require('@/assets/images/admin/doc_jpg.png')"
              alt="Left image"
            />
            <b-img
              v-if="file.type.includes('application')"
              left
              height="40"
              :src="require('@/assets/images/admin/doc_file.png')"
              alt="Left image"
            />
            <strong>
              <b-link
                :href="file ? file.data : ''"
                target="_blank"
              >
                {{ file ? file.name : '' }}
              </b-link>
            </strong>
            <span>{{ file ? `${file.size}mb` : '' }}</span>
            <!-- <span class="remove__doc">
              <b-button
                variant="flat-primary"
                @click="removeAttachment(file.data)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                  size="18"
                />
              </b-button>
            </span> -->
          </div>
          <div class="doc__desc">
            <span v-if="file.description">
              {{ file.description }}
            </span>
            <span v-else>
              <button @click="updateDescription(key)">Add a description</button>
            </span>
            <span
              v-if="file.description"
              class="edit__desc"
            >
              <b-button
                variant="flat-primary"
                @click="updateDescription(key)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                  size="18"
                />
              </b-button>
            </span>
          </div>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <div class="empty_block">
            <h4>Nothing in here yet</h4>
            <span class="align-middle">Edit this service form to add attachments.</span>
            <!-- <b-button
              type="button"
              variant="flat-primary"
              @click="addCustomField"
            >
              <span class="align-middle">Edit this service form to add attachments.</span>
            </b-button> -->
          </div>
        </b-col>
      </b-row>

      <hr class="dividerHR">

      <b-row
        class="content-header"
        style="flex-wrap: nowrap;"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          md="9"
        >
          <h3>Other Details</h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right mb-1"
          md="3"
        >
          <b-button
            v-if="canViewThisAction('update', 'ServiceForm') || canViewThisAction('update-status', 'ServiceForm') || canViewThisAction('update-duty-officer', 'ServiceForm')"
            variant="flat-primary"
            :to="{ name: 'operation-service-forms-edit', params: { id: $route.params.id }, query: { section: 'other_details' } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
          <b-button
            v-else
            v-b-tooltip.hover.bottom="'You don’t have permission to edit this information.'"
            variant="flat-primary"
            style="opacity: 0.65;"
            aria-readonly=""
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Duty Officer
            </b-th>
            <b-td>{{ serviceForm.dutyOfficer ? serviceForm.dutyOfficer.name : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Status
            </b-th>
            <b-td class="text-capitalize">
              {{ serviceForm.status ? serviceForm.status.replace("-", " ") : '-' }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <b-card
      class="mb-3 mt-3 p-2 booking_calllog calllog-details"
    >
      <b-row
        class="content-header"
        style="flex-wrap: nowrap;"
      >
        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          md="12"
        >
          <h3>
            <span class="align-middle">Documents</span>
          </h3>
        </b-col>
      </b-row>
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left booking_content_wrapper mb-2"
          cols="12"
          md="12"
        >
          <b-dropdown
            v-if="isMobile()"
            :text="currentTabTitle"
            variant="outline-primary"
          >
            <b-dropdown-item
              @click="changeTab(0, 'Service Form')"
            >
              Service Form
            </b-dropdown-item>
            <b-dropdown-item
              v-for="(tab, index) in serviceRequests"
              :key="index"
              @click="changeTab(index + 1, `${tab.stringID} ${tab.workFlow.name}`)"
            >
              {{ tab.stringID }} {{ tab.workFlow.name }}
            </b-dropdown-item>
          </b-dropdown>
          <b-tabs
            v-model="activeTab"
            pills
            class="booking__content no-pr"
            :nav-class="[isMobile() ? 'd-none' : 'tab-parent']"
          >
            <!-- Tab: Information -->
            <b-tab class="">
              <template #title>
                <span class="d-none d-sm-inline">Service Form</span>
              </template>
              <b-alert
                variant="success"
                :show="serviceForm.status == 'awaiting approval'"
                style="background: #E9EBF3 !important; width: fit-content;"
              >
                <div class="alert-body d-flex">
                  <feather-icon
                    icon="AlertCircleIcon"
                    size="18"
                    class="mt-25 mr-1"
                  />
                  <span>You need review and approve all service request in “Awaiting Approval” status before you can sign the service form.</span>
                </div>
              </b-alert>

              <!-- <b-row class="mb-2">
                <b-col
                  cols="12"
                  md="4"
                  class=""
                >
                  <span class="weight-500 fs-15">Forms</span>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class=""
                >
                  <span class="weight-500 fs-15">Status</span>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  class=""
                >
                  <span class="weight-500 fs-15">Signed PDF Copy</span>
                </b-col>
              </b-row> -->

              <b-row>
                <b-col
                  cols="12"
                  md="4"
                  class="hover-td"
                >
                  <div :class="{ 'mt-75': isMobile() }">
                    <span class="weight-500 fs-15">Forms</span>
                  </div>
                  <div
                    v-if="canViewThisAction('show', 'ServiceForm')"
                  >
                    <b-button
                      variant="link"
                      class="p-0"
                      :to="{ name: 'operation-service-forms-form-view', params: { id: serviceForm._id || 0 } }"
                    >
                      Service Form
                    </b-button>

                    <b-button
                      v-if="serviceForm.formStatus == 'Signed and Sent' && formPDFs.length"
                      v-b-tooltip.hover.top="'Download without password'"
                      variant="flat-primary"
                      class="ml-75 wf-action-button"
                      style="float: none; margin-top: 0 !important;"
                      @click="downloadSFForm(serviceForm._id, formPDFs[0].name)"
                    >
                      <feather-icon
                        icon="DownloadIcon"
                        size="16"
                      />
                    </b-button>
                  </div>
                  <div v-else>
                    <span>Service Form</span>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class=""
                >
                  <div :class="{ 'mt-75': isMobile() }">
                    <span class="weight-500 fs-15">Status</span>
                  </div>
                  <b-badge
                    class="text-capitalize mt-50"
                    :variant="`light-${resolveFormStatusVariant(serviceForm.formStatus)}`"
                    style="padding: 4px 9px; font-size: 14px; line-height: 18px; font-weight: 400;"
                  >
                    {{ serviceForm.formStatus }}
                  </b-badge>
                  <br>
                  <span v-if="serviceForm.pdfSentDate">
                    {{ dateFormatWithTime(serviceForm.pdfSentDate) }}
                  </span>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  class="list-item"
                >
                  <div :class="{ 'mt-75': isMobile() }">
                    <span class="weight-500 fs-15">Signed PDF Copy</span>
                  </div>
                  <div
                    v-if="formPDFs && formPDFs.length"
                    class="mt-50"
                  >
                    <app-collapse
                      v-if="formPDFs.length > 1"
                      class="reverse-icon"
                    >
                      <app-collapse-item title="Urn Repository Form">
                        <template
                          #header
                        >
                          <b-row
                            class="full-width"
                          >
                            <!-- Content Left -->
                            <b-col
                              class="content-header-left"
                              cols="12"
                              md="12"
                            >
                              <div
                                class="attachment-item"
                              >
                                <b-img
                                  left
                                  height="40"
                                  :src="require('@/assets/images/admin/doc_file.png')"
                                  alt="Left image"
                                />
                                <strong>
                                  <b-link
                                    :href="formPDFs[0] ? formPDFs[0].data : ''"
                                    target="_blank"
                                  >
                                    {{ formPDFs[0] ? formPDFs[0].name : '' }}
                                  </b-link>
                                </strong>

                                <b-badge
                                  variant="warning"
                                  class="ml-auto"
                                >
                                  Latest
                                </b-badge>
                              </div>
                            </b-col>
                          </b-row>
                        </template>
                        <b-row
                          v-for="(pdf, index) in formPDFs"
                          :key="index"
                          class="full-width"
                        >
                          <b-col
                            v-if="index > 0"
                            class="content-header-left mt-50"
                            cols="12"
                            md="12"
                          >
                            <div
                              class="attachment-item"
                            >
                              <b-img
                                left
                                height="40"
                                :src="require('@/assets/images/admin/doc_file.png')"
                                alt="Left image"
                              />
                              <strong>
                                <b-link
                                  :href="pdf ? pdf.data : ''"
                                  target="_blank"
                                >
                                  {{ pdf ? pdf.name : '' }}
                                </b-link>
                              </strong>
                            </div>
                          </b-col>
                        </b-row>
                      </app-collapse-item>
                    </app-collapse>
                    <div
                      v-else
                      class="attachment-item mr-28"
                    >
                      <b-img
                        left
                        height="40"
                        :src="require('@/assets/images/admin/doc_file.png')"
                        alt="Left image"
                      />
                      <strong>
                        <b-link
                          :href="formPDFs[0] ? formPDFs[0].data : ''"
                          target="_blank"
                        >
                          {{ formPDFs[0] ? formPDFs[0].name : '' }}
                        </b-link>
                      </strong>
                      <b-badge
                        variant="warning"
                        class="ml-auto"
                      >
                        Latest
                      </b-badge>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <hr
                class="dividerHR"
              >

              <h3 class="mb-2">
                Uploaded Documents
              </h3>
              <span class="align-middle">No uploaded document yet.</span>
            </b-tab>
            <b-tab
              v-for="(tab, index) in serviceRequests"
              :key="index"
            >
              <template #title>
                <span class="d-none d-sm-inline">{{ tab.stringID }} {{ tab.workFlow.name }}</span>
              </template>
              <service-request-form
                :service-request-id="tab._id"
                :file-upload-block="fileUploadBlock"
                :form-options="formOptions"
                :service-request-forms="serviceRequestForms"
                :file-upload-tasks="fileUploadTasks"
              />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      class="mb-3 mt-3 p-2 booking_calllog calllog-details"
    >
      <b-row
        class="content-header"
        style="flex-wrap: nowrap;"
      >
        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          md="9"
        >
          <h3>
            <feather-icon
              icon="PhoneCallIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Call Logs</span>
          </h3>
        </b-col>
        <b-col
          class="content-header-right text-md-right mb-1"
          md="3"
        >
          <b-button
            v-if="canViewThisAction('update', 'ServiceForm')"
            variant="flat-primary"
            :to="{ name: 'appointment-call-logs-create', query: { serviceForm: $route.params.id } }"
          >
            <feather-icon
              icon="Link2Icon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Attach Call Log</span>
          </b-button>
        </b-col>
      </b-row>
      <call-log-list />
    </b-card>

    <b-card
      class="mb-3 mt-3 p-2"
    >
      <b-row
        class="content-header"
      >
        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2 p-2"
          cols="12"
          md="12"
        >
          <h4>
            <feather-icon
              icon="ListIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Activity Logs</span>
          </h4>
        </b-col>
      </b-row>
      <app-timeline>
        <!-- FINANCIAL REPORT -->
        <app-timeline-item
          v-for="(opt, key) in serviceFormActivityLogs"
          :key="key"
          :variant="getVariant(key)"
        >

          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>{{ opt.title }}</h6>
            <small class="text-muted">{{ dateFormatWithTime(opt.time) }}</small>
          </div>
          <b-button
            v-if="opt.activityDetails && opt.activityDetails.length"
            v-b-toggle="'report-list-with-icon-' + key"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            variant="outline-primary"
          >
            Details
            <feather-icon
              icon="ChevronUpIcon"
              class="ml-50 when-open"
              size="16"
            />
            <feather-icon
              icon="ChevronDownIcon"
              class="ml-50 when-closed"
              size="16"
            />
          </b-button>
          <b-collapse
            v-if="opt.activityDetails && opt.activityDetails.length"
            :id="'report-list-with-icon-' + key"
          >
            <b-table-simple
              borderless
              responsive
            >
              <b-tbody>
                <b-tr
                  v-for="(activityDetail, activitykey) in opt.activityDetails"
                  :key="activitykey"
                >
                  <b-th style="width: 300px; max-width: 300px;">
                    {{ activityDetail.title }}
                  </b-th>
                  <b-td>{{ activityDetail.oldValue }}</b-td>
                  <b-td>{{ activityDetail.newValue }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-collapse>
        </app-timeline-item>
      </app-timeline>
    </b-card>

    <b-modal
      id="modal-update-duty-officer"
      ref="update-duty-officer-modal"
      modal-class="sign-modal-adjust"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Duty Officer"
      @ok="dutyOfficerSubmitForm"
    >
      <b-form @submit.prevent="dutyOfficerSubmitForm">
        <validation-observer
          ref="serviceFormDutyOfficerEditForm"
        >
          <b-form-group
            label="Duty Officer*"
            label-for="h-serviceForm-dutyOfficer"
          >
            <validation-provider
              #default="{ errors }"
              name="Duty Officer"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-serviceForm-dutyOfficer"
                v-model="dutyOfficer"
                label="name"
                :options="dutyOfficerOptions"
                :reduce="name => name._id"
                :clearable="false"
                placeholder="Select an option"
                @input="dutyOfficerValidation == true ? dutyOfficerValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="dutyOfficerValidation"
                class="text-danger"
              >
                {{ dutyOfficerError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="serviceForm-all-modal-update-status"
      ref="update-status-modal"
      cancel-variant="flat-primary"
      modal-class="status-update-modal sign-modal-adjust"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="submitForm"
    >
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="allserviceFormStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="status"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="statusValidation == true ? statusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-send-sms-agent"
      modal-class="sign-modal-adjust"
      title=""
      centered
      no-stacking
      hide-footer
      header-class="front-modal"
    >
      <h3>Remind agent about outstanding payment</h3>
      <p>Applicant hasn’t made full payment yet. Please remind Agent {{ serviceForm.agentName ? serviceForm.agentName : '-' }} by sending SMS to <strong>(65) {{ serviceForm.agentContact ? serviceForm.agentContact : '-' }}</strong></p>
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-agent-phone-number"
            v-model="smsText"
            placeholder="E.g. Please remind to make payment"
          />
          <small
            v-if="smsTextValidation"
            class="text-danger"
          >
            {{ smsTextError }}
          </small>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          sm="12"
          cols="12"
        >
          <b-button
            v-b-modal.modal-send-sms-customer
            variant="link"
          >
            Send to applicant instead
          </b-button>
        </b-col>
        <b-col
          md="6"
          sm="12"
          cols="12"
        >
          <div class="demo-inline-spacing ml-auto d-flex justify-content-between">
            <b-button
              variant="flat-primary"
              @click="closeAgentSmsModal"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              :disabled="!smsText || !agentContact"
              @click="sendSms('agent')"
            >
              <feather-icon
                icon="SendIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Send</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="modal-send-sms-customer"
      modal-class="sign-modal-adjust"
      title=""
      centered
      no-stacking
      hide-footer
      header-class="front-modal"
    >
      <h3>Remind applicant about outstanding payment</h3>
      <p>Applicant hasn’t made full payment yet. Please remind <strong>applicant</strong> by sending SMS to <strong>(65) {{ serviceForm.customerContact ? serviceForm.customerContact : '-' }}</strong></p>
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="smsText"
            placeholder="E.g. Please remind to make payment"
          />
          <small
            v-if="smsTextValidation"
            class="text-danger"
          >
            {{ smsTextError }}
          </small>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          sm="12"
          cols="12"
        >

          <b-button
            v-b-modal.modal-send-sms-agent
            variant="link"
          >
            Send to agent instead
          </b-button>
        </b-col>
        <b-col
          md="6"
          sm="12"
          cols="12"
        >
          <div class="demo-inline-spacing ml-auto d-flex justify-content-between">
            <b-button
              variant="flat-primary"
              @click="closeCustomerSmsModal"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              :disabled="!smsText || !customerContact"
              @click="sendSms('customer')"
            >
              <feather-icon
                icon="SendIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Send</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="modal-attachment-description"
      size="lg"
      title="Attachment Description"
      modal-class="status-update-modal"
      centered
      no-stacking
      header-class="front-modal"
      @ok="saveDescription"
      @cancel="closeDescriptionModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="description"
            placeholder="E.g. Please remind to make payment"
          />
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col
          md="12"
          class=""
        >
          <div class="demo-inline-spacing ml-auto">
            <b-button
              variant="flat-primary"
              @click="closeDescriptionModal"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              :disabled="!description || attachmentKey === ''"
              @click="saveDescription()"
            >
              <span class="align-middle">Save</span>
            </b-button>
          </div>
        </b-col>
      </b-row> -->
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!description || attachmentKey === ''"
          @click="ok()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>

    <email-compose
      v-if="serviceForm.customerName"
      :shall-show-email-compose-modal.sync="shallShowEmailComposeModal"
      :service-form.sync="serviceForm"
      :email-to-options="emailToOptions"
    />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, BForm, BFormGroup, BImg, BFormTextarea,
  BLink, VBToggle, VBTooltip, BCollapse, BTab, BTabs, BDropdown, BDropdownItem, BAlert, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store/index'
import { required } from '@validations'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import Ripple from 'vue-ripple-directive'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AllServiceRequest from '../service-request/all-service-request/AllServiceRequest.vue'
import EmailCompose from './EmailCompose.vue'
import ServiceRequestForm from './ServiceRequestForm.vue'
import CallLogList from '../../appointment/call-log/CallLogList.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BForm,
    BFormGroup,
    BImg,
    BFormTextarea,
    BLink,
    BCollapse,
    BTab,
    BTabs,
    BDropdown,
    BDropdownItem,
    BAlert,
    BBadge,

    vSelect,
    ValidationProvider,
    ValidationObserver,

    CallLogList,

    AllServiceRequest,

    AppTimeline,
    AppTimelineItem,
    AppCollapse,
    AppCollapseItem,
    EmailCompose,
    ServiceRequestForm,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      serviceForm: {},
      serviceFormID: '',
      stringID: '',
      status: 'pending',
      statusValidation: false,
      statusError: 'Valid status is required',
      dutyOfficer: '',
      dutyOfficerValidation: false,
      showCustomFormSection: false,
      dutyOfficerError: 'Valid duty officer is required',
      dutyOfficerOptions: [],
      serviceFormActivityLogs: [],
      statusDataOptions: [
        { title: 'Awaiting Approval', code: 'awaiting approval' },
        { title: 'Completed', code: 'completed' },
        { title: 'Completed and Paid', code: 'completed and paid' },
        { title: 'Pending', code: 'pending' },
      ],
      requestTypeOptions: [],
      assigneeOptions: [],
      smsText: '',
      agentContact: '',
      customerContact: '',
      smsTextValidation: false,
      smsTextError: '',
      description: '',
      attachmentKey: '',
      shallShowEmailComposeModal: false,
      required,
      emailToOptions: [],
      serviceRequests: [],
      formOptions: [],
      formPDFs: [],
      serviceRequestForms: [],
      fileUploadTasks: [],
      fileUploadBlock: '',
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
      currentTabTitle: 'Service Form',
      activeTab: 0,
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.$http.get('operation/service-forms/respond-with/duty-officers-workflow', { params: { department: true } })
      .then(response => {
        const workFlowCats = response.data.workFlowCats ?? []
        this.assigneeOptions = response.data.dutyOfficers ?? []
        this.emailToOptions = response.data.dutyOfficers ?? []
        this.smsText = `Dear Agent,
Good day to you, please be informed that you have an outstanding payment for the following service:

Service Date: DD/MM/YY
Service Time: HH:MM
Service Details: <xxxxxx>
  
Offerings: $
Chanting: $
Others: $

Total Amount: $

Please proceed to the Customer Service counter to make the payment.

Thank you and wishing you a great day ahead. 

Nirvana Memorial Garden
${this.user.name}

[This is an automated message - Please do not reply directly to this text message].`

        workFlowCats.forEach(element => {
          this.requestTypeOptions.push({
            category: element.name,
            name: null,

            _id: element._id,
          })
          element.workFlows.forEach(flow => {
            this.requestTypeOptions.push({
              name: flow.name,

              _id: flow._id,
            })
          })
        })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    this.fetchServiceForm()
  },
  methods: {
    downloadSFForm(id, name) {
      const formData = new FormData()
      formData.append('url', `${window.location.origin}/operations/service-forms/${id}/form/pdf-view`)
      formData.append('landscape', false)
      formData.append('pageRanges', 1)

      this.$http.post('operation/pdf/download', formData, {
        headers: { Accept: 'application/pdf' },
        responseType: 'arraybuffer',
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = name
          link.click()

          link.remove()
        })
    },
    fetchServiceForm() {
      this.$http.get(`operation/service-forms/${this.$route.params.id}/show`, { params: { department: true } })
        .then(response => {
          this.serviceForm = response.data.data || {}
          this.formPDFs = this.serviceForm.pdfs.reverse()
          this.dutyOfficerOptions = response.data.dutyOfficers || []
          document.title = `${response.data.data.stringID} - ${response.data.data.customerName} | ${process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'}`

          this.setData()

          // let fieldCount = 0
          // if (this.serviceForm.serviceDescription.length) {
          //   this.serviceForm.serviceDescription.forEach(arrayItem => {
          //     if (!['file', 'title-and-text'].includes(arrayItem.type)) {
          //       // eslint-disable-next-line no-plusplus
          //       fieldCount++
          //     }
          //   })
          // }

          // if (fieldCount > 0) {
          //   this.showCustomFormSection = true
          // }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })

      this.$http.get(`operation/service-forms/${this.$route.params.id}/service-requests/with-forms`, { params: { department: true } })
        .then(response => {
          this.formOptions = response.data.formOptions
          this.serviceRequestForms = response.data.serviceRequestForms || []
          this.serviceRequests = response.data.data || []
          this.fileUploadTasks = response.data.serviceRequestAttachments || []
          this.fileUploadBlock = response.data.fileUploadBlock || ''
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    changeTab(index, tabName) {
      this.activeTab = index
      this.currentTabTitle = tabName
    },
    formName(id) {
      const form = this.formOptions.find(o => o._id === id)
      if (form) {
        let fName = form.name
        if (form.formNumber && form.formNumber !== '-') {
          fName += ` (${form.formNumber})`
        }
        if (form.revision && form.revision !== '-') {
          fName += ` (${form.revision})`
        }
        return fName
      }

      return ''
    },
    resolveFormStatusVariant(status) {
      if (status === 'Signed and Sent') return 'success'
      if (status === 'Signing') return 'warning'
      return 'primary'
    },
    showModalOrWarning() {
      if (this.serviceForm.status === 'awaiting approval') {
        this.$swal({
          title: 'Warning: Service Request is ‘Awaiting Approval’ and you need to settle this first before you can change the Service Form status',
          html: '',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          imageWidth: 80,
          imageHeight: 80,
          imageAlt: 'Custom Icon',
          showCancelButton: false,
          confirmButtonText: 'Got it',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
          width: 485,
        })
      } else {
        this.$root.$emit('bv::show::modal', 'serviceForm-all-modal-update-status', '')
      }
    },
    closeCustomerSmsModal() {
      this.$root.$emit('bv::hide::modal', 'modal-send-sms-customer', '')
    },
    closeAgentSmsModal() {
      this.$root.$emit('bv::hide::modal', 'modal-send-sms-agent', '')
    },
    closeDescriptionModal() {
      this.$root.$emit('bv::hide::modal', 'modal-attachment-description', '')
    },
    sendSms(type) {
      let phone = ''
      if (type === 'agent') {
        phone = this.agentContact
      } else {
        phone = this.customerContact
      }

      this.$http
        .post('operation/service-forms/send-sms', { phone, message: this.smsText, serviceForm: this.$route.params.id })
        .then(response => {
          this.serviceForm = response.data.data || {}
          this.setData()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          if (type === 'agent') {
            this.closeAgentSmsModal()
          } else {
            this.closeCustomerSmsModal()
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    updateDescription(key) {
      this.attachmentKey = key
      this.description = this.serviceForm.attachments[key].description
      this.$root.$emit('bv::show::modal', 'modal-attachment-description', '')
    },
    removeAttachment(url) {
      this.$http
        .post('operation/service-forms/remove-attachment', { url, serviceForm: this.$route.params.id })
        .then(response => {
          this.serviceForm = response.data.data || {}
          this.setData()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getVariant(key) {
      if (key % 4 === 0) return 'info'
      if (key % 4 === 1) return 'warning'
      if (key % 4 === 2) return 'danger'
      if (key % 4 === 3) return 'purple'
      return 'primary'
    },
    saveDescription() {
      const url = this.serviceForm.attachments[this.attachmentKey].data
      this.$http
        .post('operation/service-forms/save-description', { url, description: this.description, serviceForm: this.$route.params.id })
        .then(response => {
          this.serviceForm = response.data.data || {}
          this.setData()
          this.closeDescriptionModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    setData() {
      this.serviceFormID = this.serviceForm._id
      this.status = this.serviceForm.status
      this.stringID = this.serviceForm.stringID
      this.dutyOfficer = this.serviceForm.dutyOfficer
      this.customerContact = this.serviceForm.customerContact
      this.agentContact = this.serviceForm.agentContact
      this.serviceFormActivityLogs = this.serviceForm.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
    },
    dutyOfficerSubmitForm() {
      this.$refs.serviceFormDutyOfficerEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('dutyOfficer', this.dutyOfficer)

          this.$http.patch(`operation/service-forms/${this.serviceFormID}/duty-officer/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.serviceForm.dutyOfficer = response.data.data.dutyOfficer || {}
              this.serviceForm.activityLog = response.data.data.activityLog || []
              this.setData()
              // this.$nextTick(() => {
              //   this.$refs['update-status-modal'].toggle('#toggle-btn')
              // })
              this.$swal({
                title: 'Duty Officer Updated!',
                html: `Duty Officer for <strong>${this.stringID}</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'dutyOfficer') {
                    this.dutyOfficerError = validationError.msg
                    this.dutyOfficerValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },

    submitForm() {
      this.$refs.allserviceFormStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)

          this.$http.patch(`operation/service-forms/${this.serviceFormID}/status/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.serviceForm.status = response.data.data.status || 'pending'
              this.serviceForm.activityLog = response.data.data.activityLog || []
              this.setData()
              this.$swal({
                title: 'Status Updated!',
                html: `The status for <strong>${this.stringID}</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    resolveOptionValue(val) {
      if (val === 'file') return 'Image'
      if (val === 'title-and-text') return 'Title and Text'
      if (val === 'short-answer') return 'Short Answer Field'
      if (val === 'long-answer') return 'Long Answer Field'
      if (val === 'multi-select') return 'Selection List Field (Add-Ons)'
      if (val === 'single-select') return 'Single Checkbox Field'
      return '-'
    },
  },
}
</script>

<style>
.timeline-variant-info .timeline-item-point {
  background-color: #00cfe8 !important;
}
.timeline-variant-purple .timeline-item-point {
  background-color: #7367F0 !important;
}
.tab-parent .nav-item {
  margin-bottom: 0.75rem !important;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
